
import './Footer.css'
import React from 'react'
import logo from '../../../images/logo-pasta-camerino-200px.png'
import fbIcon from '../../../images/icons/fb-icon.png'
import instagramIcon from '../../../images/icons/instagram-icon.png'
import youtubeIcon from '../../../images/icons/youtube-icon.png'

export default function Footer ({ locale }) {
  return (
    <footer>
      <div>
          <h4>{locale === "en" ? "PRODUCTS" : "SHOP"}</h4>
        {locale === "en" ? 
          <> 
            <p><a href="https://www.lapastadicamerino.it/wp-content/uploads/2022/05/pdc_catalogo-2021_en.pdf" target="_blank">Our products</a></p>
          </> : 
          <>
            <p><a href=' https://lapastadicamerino.it/pasta-alluovo/'>Pasta all'uovo</a></p>
            <p><a href='https://lapastadicamerino.it/pasta-di-semola/'>Pasta di semola</a></p>
            <p><a href='https://lapastadicamerino.it/pasta-bio/'>Pasta Bio</a></p>
            <p><a href='https://lapastadicamerino.it/hammurabi/'>Pasta Hammurabi</a></p>
          </>
       }
      </div>
      <div>
        <h4>{locale === "en" ? "SHOP" : "I NOSTRI PRODOTTI"}</h4>
        {locale === "en" ? 
         <>
           <p><a href='https://lapastadicamerino.it/en/egg-pasta' target="_blank">Egg pasta</a></p>
           <p><a href='https://www.lapastadicamerino.it/en/semolina-pasta/' target="_blank">Semolina pasta</a></p>
           <p><a href='https://www.lapastadicamerino.it/en/whole-wheat-semolina-pasta/' target="_blank">Whole weath pasta</a></p>
           <p><a href='https://www.lapastadicamerino.it/en/organic-pasta/' target="_blank">Organic pasta</a></p>
         </>  
         : 
         <p><a href='https://www.lapastadicamerino.it/wp-content/uploads/2021/09/pdc_catalogo2021.pdf'>Catalogo Prodotti</a></p>

      }
      </div>
    
      <div>
        <h4>ENTROTERRA SPA</h4>
        <p>Località Torre del Parco</p>
        <p>Tel: +39.0737.640498</p>
        <p>Fax: +39.0737.640713</p>
        <p>info@pastaentroterra.com</p>
        <p>P.IVA: 01734520438</p>
      </div>
      <div>
        {/* <img id='logo-footer' src={logo} alt='' /> */}
        <div id='icons-container'>
          <a href='https://www.facebook.com/LaPastaDiCamerino'> <img src={fbIcon} alt='' /></a>
          <a href='https://www.instagram.com/lapastadicamerino/'><img src={instagramIcon} alt='' /></a>
          <a href='https://www.youtube.com/channel/UCyyg88VN0buzML3TSdTVGhw'><img src={youtubeIcon} alt='' /></a>
        </div>
      </div>
   
    </footer>
  )
}
