import './Hero.css'
import React from 'react'
import logo from '../../images/logo-hero.png'

export default function Hero ({ locale }) {
  const title = locale === "en" ? "The only ones with a 100% transparent supply chain" : "Gli unici con Filiera 100% trasparente"
  const subtitle = locale === "en" ? "We have always been proud to declare the origins of the high quality ingredients used in our products." : "Da sempre siamo fieri di dichiarare la provenienza degli ingredienti di alta qualità usati per i nostri prodotti."
  return (
    <div id='hero'>
      <img src={logo} alt='' />
      <h1>{title}</h1>
      <p>{subtitle}</p>
    </div>
  )
}
