import React, { useState } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import Home from './pages/Home/Home'
import HomeEn from './pages/en/HomeEn'
import ErrorBoundary from './components/ErrorBoundary'
import ProductPage from './pages/ProductPage/ProductPage'
import ProductPageEn from './pages/en/ProductPageEn'
import Layout from './components/Layout/Layout'
import useGaTracker from './useGaTracker'
import redirectsQrCode from './redirectsQrCode'

function App () {
  // Google Analytics tracker 
  useGaTracker();
  redirectsQrCode()


  return (
    <>
      <ErrorBoundary>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/en' component={HomeEn} />
            <Route path='/lotto/:id' component={ProductPage} />
            <Route path='/en/lotto/:id' component={ProductPageEn} />
          </Switch>
      </ErrorBoundary>
    </>
  )
}

export default App
