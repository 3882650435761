import './BoxFormPage.css'
import GetProductInfoInput from './GetProductInfoInput/GetProductInfoInput'
import busta from '../../images/busta-1.png'
import busta2 from '../../images/busta-2.png'
import busta3 from '../../images/busta-3.png'
import paccoPasta from '../../images/pacco-pasta.png'

const BoxFormPage = ({ pageId, locale }) => {
  const title = locale === "en" ? "Search the package for the lot number or enter it in the space below by typing only the numeric code as indicated in the image below." : "Cerca sulla confezione il numero relativo al lotto o inseriscilo nello spazio sottostante digitando solo il codice numerico come indicato nella figura sotto."
  return (
    <div id='box-form-page'>
      <div>
        <h3>{title}</h3>
        <GetProductInfoInput pageId={pageId} locale={locale}/>
        <div id='images-container'>
          <img src={busta} alt='busta' />
          <img src={busta3} alt='busta-2' />
          <img src={busta2} alt='vassoio' />
        </div>
      </div>
      {/* <div id='pasta-package-container'>
        <img src={paccoPasta} alt='pacco-pasta' />
      </div> */}
    </div>
  )
}

export default BoxFormPage
