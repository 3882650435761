import './DisplayProducts.css'
import { AppContext } from '../../contexts/AppContext'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

const DisplayProducts = ({ locale }) => {
  const { productsToDisplay, inputValue } = useContext(AppContext)
  const noProductsText = locale === "en" ? "No products match the research" : "Non ci sono prodotti che corrispondono alla ricerca"
  
  return (
    <div id='products-container'>
      {inputValue !== '' && productsToDisplay.map((product, i) => {
        const productLink = locale === "en" ? "/en/lotto/" + product.formato_ID : "/lotto/" + product.formato_ID
        return (
          <Link key={i} to={productLink}>
            <div id='product-box'>
              <img src={`http://camerino.fabrizio.in/${product.formato_foto}`} alt={product.prodotto_nome} />
              <h3><strong>{locale === "en" ? product.prodotto_nome : product.prodotto_nome}</strong></h3>
              <p>{locale === "en" ? product.formato_nome_inglese : product.formato_nome}</p>
              <br />
            </div>
          </Link>
        )
      })}
      {inputValue && productsToDisplay.length === 0 && <p id="no-products-text">{noProductsText}</p>}
    </div>
  )
}

export default DisplayProducts
