import './Video.css'
import React from 'react'
import YouTubePlayer from 'react-player/lib/players/YouTube'
import ReactPlayer from 'react-player'

const Video = ({ locale }) => {
  const title = locale === "en" ? "How do we make pasta?" : "Come facciamo la pasta?"
  return (
    <div id='video-last-page'>
      <h2>{title}</h2>
      <div id='video-container-mobile'>
        <ReactPlayer
          url='https://www.youtube.com/watch?v=ae97v3ynIvg'
          width='100%'
          height='350px'
        />
      </div>
      <div id='video-container-desktop'>
        <p align='center'>
          <ReactPlayer
            url='https://www.youtube.com/watch?v=ae97v3ynIvg'
            width='100%'
            height='600px'
          />
        </p>
      </div>
    </div>
  )
}

export default Video
