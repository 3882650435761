export default function redirectsQrCode()  {
  const appUrl = process.env.REACT_APP_CLIENT_URL
  switch(window.location.href) {
      case appUrl + '/prodotti/pasta-farro-bio_4/1/' :
        window.location.href = '/lotto/85'
        break;
      case appUrl + '/prodotti/pasta-alluovo-lunga_1/1/' :
        window.location.href = "/"
        break;
      case appUrl + '/prodotti/pasta-uovo-bio_5/1/' :
        window.location.href = "/"
        break;
      case appUrl + '/1/' :
        window.location.href = "/"
        break;
        default: 
        console.log('Switch case')
   }
}
   


   


