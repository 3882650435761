import './ProductPage.css'
import React, { useContext, useEffect } from 'react'
import Hero from '../../components/Hero/Hero'
import BoxFormPage from '../../components/BoxFormPage/BoxFormPage'
import { AppContext } from '../../contexts/AppContext'
import HeroLastPage from '../../components/LastPage/HeroLastPage/HeroLastPage'
import Video from '../../components/LastPage/Video/Video'
import Box from '../../components/LastPage/Box/Box'
import imgFirstBox from '../../images/img-first-box.png'
import imgSecondBox from '../../images/img-second-box.png'
import imgThirdBox from '../../images/img-third-box.png'
import imgFourthBox from '../../images/img-fourth-box.png'
import Header from '../../components/Layout/Header/Header'
import Footer from '../../components/Layout/Footer/Footer'
import SubFooter from '../../components/Layout/Footer/SubFooter/SubFooter'

const ProductPage = props => {
  const { redirect, singleProduct } = useContext(AppContext)
  console.log(singleProduct, 'singleProduct')

  // Only 2 boxes to display (category 4 and 6)
  if (redirect && singleProduct.length === 1) {
    return (
      <>
      <Header/>
        <HeroLastPage />
        <div class='boxes-container'>
          <Box
            img={imgFirstBox}
            title='Dove prendiamo il grano?'
            subtitle={singleProduct[0].llregion}
            text={singleProduct[0].lldescri}
            myClass='box row-reverse'
          />
          <Box
            img={imgSecondBox}
            title='Chi lo macina?'
            subtitle={singleProduct[0].andescri}
            text={singleProduct[0].anllnote}
            myClass='box'
          />
          <Video />
        </div>
        <Footer/>
      </>
    )
  }
  // 4 Boxes to display
  if (redirect) {
    return (
      <>
      <Header/>
        <HeroLastPage />
        <div class='boxes-container'>
          <Box
            img={imgFirstBox}
            title='Dove prendiamo il grano?'
            subtitle={singleProduct[1].llregion}
            text={singleProduct[1].lldescri}
            myClass='box row-reverse'
          />
          <Box
            img={imgSecondBox}
            title='Chi lo macina?'
            subtitle={singleProduct[1].andescri}
            text={singleProduct[1].anllnote}
            myClass='box'
          />
          <Box
            img={imgThirdBox}
            title='Dove alleviamo le galline?'
            subtitle={singleProduct[0].llregion}
            text={singleProduct[0].lldescri}
            myClass='box row-reverse'
          />
          <Box
            img={imgFourthBox}
            title='Da dove vengono le uova?'
            subtitle={singleProduct[0].andescri}
            text={singleProduct[0].anllnote}
            myClass='box'
          />
          <Video />
        </div>
        <Footer/>
      </>
    )
  }
  return (
    <>
    <Header/>
      <Hero />
      <BoxFormPage pageId={props.match.params.id} locale="ita" />
    <Footer/>
    <SubFooter/>
    </>
  )
}

export default ProductPage
