import '../ProductPage/ProductPage.css'
import React, { useContext, useEffect } from 'react'
import Hero from '../../components/Hero/Hero'
import BoxFormPage from '../../components/BoxFormPage/BoxFormPage'
import { AppContext } from '../../contexts/AppContext'
import HeroLastPage from '../../components/LastPage/HeroLastPage/HeroLastPage'
import Video from '../../components/LastPage/Video/Video'
import Box from '../../components/LastPage/Box/Box'
import imgFirstBox from '../../images/img-first-box.png'
import imgSecondBox from '../../images/img-second-box.png'
import imgThirdBox from '../../images/img-third-box.png'
import imgFourthBox from '../../images/img-fourth-box.png'
import Header from '../../components/Layout/Header/Header'
import Footer from '../../components/Layout/Footer/Footer'

const ProductPage = props => {
  const { redirect, singleProduct } = useContext(AppContext)

  // Only 2 boxes to display (category 4 and 6)
  if (redirect && singleProduct.length === 1) {
    return (
      <>
      <Header locale="en"/>
        <HeroLastPage />
        <div class='boxes-container'>
          <Box
            img={imgFirstBox}
            title="Where do we get the wheat?"
            subtitle={singleProduct[0].llregion_en}
            text={singleProduct[0].lldescri_en}
            myClass='box row-reverse'
          />
          <Box
            img={imgSecondBox}
            title='Who grinds it?'
            subtitle={singleProduct[0].andescri}
            text={singleProduct[0].anllnote_en}
            myClass='box'
          />
          <Video locale="en" />
        </div>
        <Footer locale="en"/>
      </>
    )
  }
  // 4 Boxes to display
  if (redirect) {
    return (
      <>
        <HeroLastPage />
        <div class='boxes-container'>
          <Box
            img={imgFirstBox}
            title='Where do we get the wheat?'
            subtitle={singleProduct[0].llregion_en}
            text={singleProduct[0].lldescri_en}
            myClass='box row-reverse'
          />
          <Box
            img={imgSecondBox}
            title='Who grinds it?'
            subtitle={singleProduct[0].andescri}
            text={singleProduct[0].anllnote_en}
            myClass='box'
          />
          <Box
            img={imgThirdBox}
            title='Where do we raise the chickens?'
            subtitle={singleProduct[1].llregion_en}
            text={singleProduct[1].lldescri_en}
            myClass='box row-reverse'
          />
          <Box
            img={imgFourthBox}
            title='Where do the eggs come from?'
            subtitle={singleProduct[1].andescri_en}
            text={singleProduct[1].anllnote_en}
            myClass='box'
          />
          <Video locale="en"/>
        </div>
      </>
    )
  }
  return (
    <>
    <Header locale="en" />
      <Hero locale="en"/>
      <BoxFormPage pageId={props.match.params.id} locale="en"/>
    <Footer locale="en"/>
    </>
  )
}

export default ProductPage
