import './Box.css'
import styled from 'styled-components'

const Container = styled.div`
   margin: 20px 0 60px
`
const Title = styled.h2`
  font-size: 26px;
  color: #1a5632;
  font-family: 'Domine', serif;
`

const SubTitle = styled.h3`
  font-size: 20px;
  color: #4b402a;
  text-transform: uppercase;
`

const Text = styled.p`
   color: #4b402a;
   line-height: 160%;
   @media(min-width: 968px){
      max-width: 90%
   }
`

const Box = ({ img, title, subtitle, text, myClass }) => {
  return (
    <Container className={myClass}>
      <div>
        <img src={img} />
      </div>
      <div>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        <Text>{text}</Text>
      </div>
    </Container>
  )
}

export default Box
