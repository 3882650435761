import './HeroLastPage.css'
import React, { useContext } from 'react'
import logo from '../../../images/logo-last-page.png'
import { AppContext } from '../../../contexts/AppContext'

const HeroLastPage = ({ locale }) => {
  const { singleProduct } = useContext(AppContext)
  console.log(singleProduct, 'singleProduct')
  return (
    <div id='hero-last-page'>
      <div>
        <img src={logo} alt='logo-last-page' />
      </div>
      <div id='product-image-container'>
        <img src={`http://camerino.fabrizio.in/${singleProduct[0].Foto}`} />
      </div>
    </div>
  )
}

export default HeroLastPage
