import './Home.css'
import BoxHome from '../../components/BoxHome/BoxHome'
import DisplayProducts from '../../components/DisplayProducts/DisplayProducts'
import Hero from '../../components/Hero/Hero'
import React, { useEffect, useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import Header from '../../components/Layout/Header/Header'
import Footer from '../../components/Layout/Footer/Footer'
import SubFooter from '../../components/Layout/Footer/SubFooter/SubFooter'

const Home = () => {
  const { setRedirect } = useContext(AppContext)

  useEffect(() => {
    setRedirect(false)
  }, [])

  return (
    <>
    <Header/>
      <Hero />
      <BoxHome />
      <div id='home-display-products'>
        <DisplayProducts />
      </div>
      <Footer/>
      <SubFooter/>
    </>
  )
}

export default Home
