import './BoxHome.css'
import Search from './Search/Search'
import img from '../../images/filiera-search-box-2.jpg'

const BoxHome = ({ locale }) => {
  const title = locale === 'en' ? "We have always been proud to declare the origins of the high quality ingredients used in our products." : 'Da sempre siamo fieri di dichiarare la provenienza degli ingredienti di alta qualità usati per i nostri prodotti.'
  const subtitle = locale === "en" ? "Type in the name of your product below." : "Digita qui sotto il nome del tuo prodotto."
  return (
    <div id='box-home'>
      <div id='search-box-img-container'>
        <img src={img} alt='filiera-search-box' />
      </div>
      <div>
        <h2>{title}</h2>
        <p id='box-home-subtitle'>{subtitle}</p>
        <Search locale={locale}/>
      </div>
    </div>
  )
}

export default BoxHome
