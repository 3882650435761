import '../Home/Home.css'
import BoxHome from '../../components/BoxHome/BoxHome'
import DisplayProducts from '../../components/DisplayProducts/DisplayProducts'
import Hero from '../../components/Hero/Hero'
import React, { useEffect, useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import Header from '../../components/Layout/Header/Header'
import Footer from '../../components/Layout/Footer/Footer'
import SubFooter from '../../components/Layout/Footer/SubFooter/SubFooter'

const HomeEn = () => {
  const { setRedirect } = useContext(AppContext)

  useEffect(() => {
    setRedirect(false)
  }, [])

  return (
    <>
    <Header locale="en"/>
      <Hero locale="en"/>
      <BoxHome locale="en"/>
      <div id='home-display-products'>
        <DisplayProducts locale="en"/>
      </div>
    <Footer locale="en"/>
    <SubFooter locale="en" />
    </>
  )
}

export default HomeEn
