import './Search.css'
import React, { useContext } from 'react'
import { AppContext } from '../../../contexts/AppContext'

export default function Search ({ locale }) {
  const { setInputValue, productsToDisplay, inputValue } = useContext(AppContext)
  const placeholderText = locale === "en" ? 'Type in the name of your product...' : 'Digita qui il nome del prodotto...'
  const chooseYourFormatText = locale === "en" ? "Choose your format" : "Scegli il tuo formato"
  return (
    <>
      <input id='search-input' placeholder={placeholderText} onChange={(e) => setInputValue(e.target.value)} />
      {inputValue !== '' && productsToDisplay.length > 0 && <p>{chooseYourFormatText}</p>}
    </>
  )
}
