import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'

export const AppContext = createContext()

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const AppContextProvider = (props) => {
  const [products, setProducts] = useState([])
  const [productsToDisplay, setProductsToDisplay] = useState(products)
  const [inputValue, setInputValue] = useState(null)
  // To display the product in the last page
  const [redirect, setRedirect] = useState(false)
  const [singleProduct, setSingleProduct] = useState(null)
  const [locale, setLocale] = useState(null)

  useEffect(() => {
    async function getProducts () {
      const response = await service.get(`/api/search/?inputValue=${inputValue}`)
      setProductsToDisplay(response.data)
    }
    getProducts()
  }, [inputValue])

  return (
    <AppContext.Provider value={{
      products,
      setProducts,
      productsToDisplay,
      setInputValue,
      inputValue,
      setSingleProduct,
      redirect,
      singleProduct,
      setRedirect
    }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
