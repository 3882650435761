import './SubFooter.css'
import React from 'react'
import cardsIcon from '../../../../images/icons/cards-icon.png'

export default function SubFooter () {
  return (
    <div id='subfooter'>
      <div>
        <p id='copyright'>©ENTROTERRA SPA. All right reserved.</p>
        <p><a href='https://lapastadicamerino.it/privacy-policy/'>Privacy Policy</a></p>
        <p><a href='https://lapastadicamerino.it/condizioni-generali-di-vendita/'>Termini e condizioni</a></p>
        <p><a href='https://www.lapastadicamerino.it/credits/'>Credits</a></p>
      </div>
      <div>
        <img height='22px' src={cardsIcon} alt='' />
      </div>
    </div>
  )
}
