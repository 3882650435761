import './GetProductInfoInput.css'
import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { AppContext } from '../../../contexts/AppContext'
import axios from 'axios'

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const GetProductInfoInput = ({ pageId, locale }) => {
  const [lottoID, setLottoID] = useState(null)
  const { setSingleProduct, setRedirect } = useContext(AppContext)

  const inputPlaceholderText = locale === "en" ? "ENTER THE LOT NUMBER" : "INSERISCI IL LOTTO"
  const buttonText = locale === "en" ? "DISCOVER THE CHAIN" : "SCOPRI LA FILIERA"

  console.log(locale, 'locale getProductsInfo')

  const onSubmitHandler = async (e) => {
    e.preventDefault()
    try {
      const response = await service.get(`/api/lotto/?ID=${pageId}&lotto=${lottoID}`)
      if (response.data === 'Nessun risultato') alert('Numero lotto non valido')
      else {
        setSingleProduct(response.data)
        setRedirect(true)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <form id='form-product-page' onSubmit={onSubmitHandler}>
      <input onChange={(e) => setLottoID(e.target.value)} type='text' placeholder={inputPlaceholderText} />
      <button>{buttonText}</button>
    </form>
  )
}

export default GetProductInfoInput
