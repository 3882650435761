import './Header.css'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../images/logo-pasta-camerino-200px.png'
import cartIcon from '../../../images/icons/cart-icon.png'
import itaFlag from '../../../images/icons/ita-flag.png'
import enFlag from "../../../images/icons/en-flag.png"

export default function Header({ locale }) {
  const [showMenu, setShowMenu] = useState(!(window.innerWidth < 968))

  const flagIcon = locale === "en" ? itaFlag : enFlag
  const logoLink = locale === "en" ? "/en" : "/"

  const itaMenu = () => {
    return (
      <nav style={{
        display: !showMenu && "none"
      }}>
        <ul>
          <li><a href='https://lapastadicamerino.it/prodotti/'>Prodotti</a></li>
          <li><a href='https://lapastadicamerino.it/pastificio/'>Pastificio</a></li>
          {/* <li><a href=''>Filiera</a></li> */}
          <li><a href='https://lapastadicamerino.it/fatta-nelle-marche/'>Territorio</a></li>
          <li><a href='https://lapastadicamerino.it/storie-di-pasta/'>Storie di pasta</a></li>
          <li><a href='https://lapastadicamerino.it/ricette/'>Ricette</a></li>
          <li id='shop'><img src={cartIcon} alt='icona-carrello' />
            <a href='https://lapastadicamerino.it/shop/'>Shop</a>
          </li>
          <li><Link to="/en"><img src={flagIcon} alt="ita-flag" /></Link></li>
        </ul>
      </nav>
    )
  }

  const enMenu = () => {
    return (
      <nav>
        <ul>
          <li><a href='https://www.lapastadicamerino.it/en/products/'>Products</a></li>
          <li><a href='https://www.lapastadicamerino.it/en/the-territory/'>Territory</a></li>
          <li><a href='https://www.lapastadicamerino.it/en/the-pasta-factory/'>The pasta factory</a></li>
          {/* <li>{flag}</li> */}
          <li><Link to="/"><img src={flagIcon} alt="en-flag" /></Link></li>

        </ul>
      </nav>
    )
  }

  return (
    <>
      <header>
        <div id='logo-container'>
          <Link to={logoLink}><img src={logo} /></Link>
          <div id='burger-icon' style={{ cursor: "pointer" }} onClick={() => setShowMenu(!showMenu)}>
            {!showMenu
              ? <>
                <div className='line' />
                <div className='line' />
                <div className='line' />
              </>
              : <div id='x'>x</div>}
          </div>
        </div>
        {showMenu && locale === "en" ? enMenu() : itaMenu()}

      </header>
    </>
  )
}
